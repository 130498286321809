import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { initializeApp } from 'firebase/app'

import {
    ThemeProvider as MaterialThemeProvider,
    CssBaseline
} from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import theme from 'app/theme'

import App from './App'

import { envConfig } from 'modules'
import { AuthProvider, NotificationProvider } from 'providers'

// FullStory
import { init as initFullStory } from '@fullstory/browser'

//Sentry
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
// import SentryFullStory from '@sentry/fullstory'

const isLocalhost = process.env.NODE_ENV === 'development'
const releaseName = process.env.REACT_APP_VERSION

initFullStory({ orgId: '15F24V', devMode: true }) // @TODO We should enable sentry for the Prod env only

if (!isLocalhost) {
    Sentry.init({
        dsn: 'https://5d020f21482843fa8d57dd26850da803@o999875.ingest.sentry.io/6064534', // embed-playbook project
        integrations: [
            new Integrations.BrowserTracing()
            // new SentryFullStory('guiddeco') @TODO We should enable sentry for the Prod env only
        ],
        environment: envConfig.firebaseConfig.environment,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0,
        release: releaseName
    })
    Sentry.setTag('project-id', envConfig.firebaseConfig.projectId)
}

initializeApp(envConfig.firebaseConfig)

const root = createRoot(document.getElementById('root') as Element)

root.render(
    <MaterialThemeProvider theme={theme}>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthProvider>
                    <NotificationProvider>
                        <App />
                    </NotificationProvider>
                </AuthProvider>
            </BrowserRouter>
        </MuiThemeProvider>
    </MaterialThemeProvider>
)
