import { useEffect } from 'react'
import { useBoolean } from './use-boolean'

export const useClipboard = () => {
    const clipboardApi = useBoolean()

    const setClipboardApi = clipboardApi.set
    useEffect(() => {
        const getNavigationStatus = async () => {
            if (!navigator?.permissions?.query) return

            navigator.permissions
                .query({ name: 'clipboard-write' as any })
                .then(({ state }) => setClipboardApi(state !== 'denied'))
                .catch(() => setClipboardApi(false))
        }

        getNavigationStatus()
    }, [setClipboardApi])

    return clipboardApi.isTrue
}
