import { useEffect } from 'react'

import { PlaybookType } from 'app/types'

import { useBoolean } from 'hooks'

import { CallToAction } from './CallToAction'

import { subscribe, unsubscribe } from 'modules/events'

type Props = {
    videoElement: HTMLVideoElement | null
    playbook: PlaybookType
}

export const CallToActionWrapperVideo = ({ videoElement, playbook }: Props) => {
    const { duration, cta, slicingSuggestion } = playbook

    const { image, link = '', start = 0, end = duration || 0 } = cta || {}

    const showCta = useBoolean()

    const isHoverControls = useBoolean()

    useEffect(() => {
        if (!videoElement) return

        const handleShowVideoCta = (e: Event) => {
            const time = Math.floor((e.target as HTMLVideoElement).currentTime)

            if (time >= start && time <= end) showCta.setTrue()
            else showCta.setFalse()
        }

        videoElement.addEventListener('timeupdate', handleShowVideoCta)
        return () => {
            videoElement.removeEventListener('timeupdate', handleShowVideoCta)
        }
    }, [
        end,
        playbook,
        start,
        videoElement,
        image,
        link,
        slicingSuggestion,
        cta,
        showCta
    ])

    useEffect(() => {
        const handleHover = (data: any) => {
            isHoverControls.set(data.detail.state)
        }

        subscribe('hoverStateChanged', handleHover)

        return () => {
            unsubscribe('hoverStateChanged', handleHover)
        }
    }, [isHoverControls])

    if (!cta || showCta.isFalse) return null

    return (
        <CallToAction
            cta={cta}
            playbook={playbook}
            hoverControls={isHoverControls.isTrue}
            videoElement={videoElement}
        />
    )
}
