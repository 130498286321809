import { useEffect, useMemo, useState } from 'react'

import { MultipleCtaType, SingleCtaType, StepType } from 'app/types'
import { publish, subscribe, unsubscribe } from 'modules/events'

import { useBoolean } from 'hooks'

import { CallToAction } from './CallToAction'
import { CallToActionMultiple } from './CallToActionMultiple'
import { CallToActionWrapperProps } from './CallToActionWrapper'

const fetchImageAsBlob = async (url: string) => {
    const response = await fetch(url)
    const blob = await response.blob()

    return URL.createObjectURL(blob)
}

const processStep = async (step: StepType) => {
    if (!(step.cta?.ctaType === 'single' && Boolean(step.cta.action?.image)))
        return step

    const blobUrl = await fetchImageAsBlob(step.cta.action.image as string)

    return {
        ...step,
        cta: {
            ...step.cta,
            action: {
                ...step.cta.action,
                image: blobUrl
            }
        }
    }
}

export const CallToActionWrapperQG = ({
    videoElement,
    playbook,
    isFreePlan = false,
    onLastStepSkip,
    onLastStepClickCta
}: CallToActionWrapperProps) => {
    const { steps = [], slicingSuggestion, duration } = playbook

    const [localSteps, setLocalSteps] = useState<StepType[]>([])
    const [currentTime, setCurrentTime] = useState(0)
    const isHoverControls = useBoolean()

    const lastStep = slicingSuggestion?.[slicingSuggestion?.length - 1]

    const isLastStep =
        lastStep && currentTime >= lastStep.start && currentTime <= lastStep.end

    const activeChapterData = useMemo(() => {
        if (!slicingSuggestion) return null

        const activeIdx = slicingSuggestion.findIndex(
            chapter => currentTime > chapter.start && currentTime <= chapter.end
        )

        if (activeIdx === -1) return null

        return {
            index: activeIdx,
            chapter: slicingSuggestion[activeIdx]
        }
    }, [currentTime, slicingSuggestion])

    const multipleCta = useMemo(() => {
        if (!activeChapterData || !videoElement) return null

        if (steps[activeChapterData.index]?.cta?.ctaType !== 'multiple')
            return null

        const delta = activeChapterData.chapter.end - currentTime

        if (delta <= 0.3 && delta >= 0) {
            // pause the video only if the video is not in full screen mode
            if (!document.fullscreenElement) videoElement.pause()

            return steps[activeChapterData.index].cta as MultipleCtaType
        }

        return null
    }, [activeChapterData, currentTime, steps, videoElement])
    const singleCta = useMemo(() => {
        if (!videoElement || !activeChapterData) return null

        const currentCTA = localSteps[activeChapterData?.index]?.cta

        if (!currentCTA) return null
        if (currentCTA?.ctaType !== 'single') return null
        if (!currentCTA.action?.enabled) return null

        return (localSteps[activeChapterData.index]?.cta as SingleCtaType)
            .action
    }, [videoElement, localSteps, activeChapterData])

    useEffect(() => {
        Promise.all(playbook.steps.map(processStep)).then(setLocalSteps)
    }, [playbook.steps])

    useEffect(() => {
        if (!videoElement) return

        const handleTimeUpdate = (e: Event) => {
            setCurrentTime((e.target as HTMLVideoElement).currentTime)
        }

        videoElement.addEventListener('timeupdate', handleTimeUpdate)
        return () => {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate)
        }
    }, [videoElement])

    useEffect(() => {
        const handleHover = (data: any) => {
            isHoverControls.set(data.detail.state)
        }

        subscribe('hoverStateChanged', handleHover)

        return () => {
            unsubscribe('hoverStateChanged', handleHover)
        }
    }, [isHoverControls])

    if (!videoElement) return null

    if (singleCta) {
        return (
            <CallToAction
                cta={singleCta}
                videoElement={videoElement}
                playbook={playbook}
                hoverControls={isHoverControls.isTrue}
            />
        )
    }

    if (multipleCta) {
        return (
            <CallToActionMultiple
                qgCTA={multipleCta}
                playbook={playbook}
                videoElement={videoElement}
                isFreePlan={isFreePlan}
                onLastStepClickCta={() => {
                    if (isLastStep) {
                        onLastStepClickCta?.()
                    }
                }}
                onSkip={() => {
                    if (isLastStep && onLastStepSkip) {
                        onLastStepSkip()
                        publish('videoPausedByCta', { state: false })
                        return
                    }
                    // for last step set the time to the end of the video
                    // hide the multi cta and play the video.
                    // playing the video prevents the user to click twice in order to restart the video
                    if (isLastStep) {
                        videoElement.currentTime = duration
                        return
                    }
                    // for any step but last continue to play
                    videoElement.play()
                    publish('videoPausedByCta', { state: false })
                }}
            />
        )
    }

    return null
}
