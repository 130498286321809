import { useContext, memo } from 'react'
import clsx from 'clsx'

import { Avatar, Box, makeStyles, Typography } from '@material-ui/core'
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from '@material-ui/lab'
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked'

import { PlaylistContext } from 'pages/playlist/PagePlaylist'

import { formatTime } from 'modules'

const useStyles = makeStyles({
    timeLine: {
        padding: 0
    },
    timeLineItem: {
        minHeight: '90px',
        '&:before': {
            display: 'none'
        }
    },
    enabledTimeLineItem: {
        cursor: 'pointer'
    },
    playlistInfo: {
        marginTop: '-16px'
    },
    timeLineContent: {
        padding: '6px 24px 6px 16px',
        width: '100%'
    },
    title: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    timeLineSeparator: {
        marginLeft: '-6px'
    },
    notActivePart: {
        opacity: 0.5
    },
    index: {
        position: 'absolute',
        lineHeight: 1
    },
    thumbnail: {
        width: '120px',
        minWidth: '120px',
        height: '70px',
        backgroundColor: 'black',
        border: '1px solid gainsboro'
    },
    avatar: {
        width: '20px',
        height: '20px'
    }
})

type Props = {
    playlist: any
}

export const Playlist = memo(({ playlist }: Props) => {
    const classes = useStyles()

    const { activePlaybook, setActivePlaybook, playbookList } =
        useContext(PlaylistContext)

    return (
        <Timeline classes={{ root: classes.timeLine }}>
            <TimelineItem
                classes={{
                    root: classes.timeLineItem
                }}
            >
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.playlistInfo}>
                    <Typography>
                        <b>{playlist.title}</b>
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Avatar
                                className={classes.avatar}
                                src={playlist?.userImage}
                            />
                            <Box mx={1}>
                                <Typography variant="body2">
                                    {playlist.username}
                                </Typography>
                            </Box>
                        </Box>

                        {playbookList?.length && (
                            <Box ml={1}>
                                <Typography variant="body2">
                                    {activePlaybook + 1}/{playbookList?.length}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </TimelineContent>
            </TimelineItem>

            {playbookList?.map((playbook: any, idx: number) => (
                <TimelineItem
                    key={playbook.id}
                    classes={{
                        root: clsx(
                            classes.timeLineItem,
                            classes.enabledTimeLineItem
                        )
                    }}
                    // @ts-ignore
                    onClick={() => setActivePlaybook(idx)}
                >
                    <TimelineSeparator className={classes.timeLineSeparator}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            className={
                                idx !== activePlaybook
                                    ? classes.notActivePart
                                    : undefined
                            }
                        >
                            <CircleIcon />
                            <Typography
                                className={classes.index}
                                variant="body2"
                            >
                                {idx + 1}
                            </Typography>
                        </Box>
                        {idx !== playbookList?.length - 1 && (
                            <TimelineConnector />
                        )}
                    </TimelineSeparator>

                    <TimelineContent className={classes.timeLineContent}>
                        <Box display="flex">
                            <img
                                className={classes.thumbnail}
                                src={playbook.screenshotUrl}
                                alt="thumbnail"
                                draggable={false}
                            />

                            <Box ml={1} pr={2}>
                                <Typography
                                    className={classes.title}
                                    title={playbook.title}
                                >
                                    {playbook.title}
                                </Typography>
                                <Typography variant="body2">
                                    {formatTime(playbook.duration)}
                                </Typography>
                            </Box>
                        </Box>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    )
})
