export const firebaseConfig = {
    "projectId": "guidde-dev3",
    "appId": "1:912904406324:web:a1c1bd34274ec9063a1161",
    "databaseURL": "https://guidde-dev3-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev3.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyCb662XeXbw2AV658Zb5A7CmBbw_a2XLhw",
    "authDomain": "dev3.guidde.com",
    "messagingSenderId": "912904406324",
    "environment": "guidde-dev3"
}

export const STIGG_CLIENT_KEY =
'client-5759ff8a-56b4-4214-8ca2-b89844ae8fd3:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'47910-93439a'