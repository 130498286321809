import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AnyPlaybookType, PlaylistType } from 'app/types'
import {
    playbookToAnalyticsProps,
    globalProperties,
    userProperties,
    isLocalhost,
    host,
    request
} from 'modules'

import { useQueryParams } from './use-query-params'

type Segment = {
    start: number // second point
    end: number
}

type CurrentSegment = {
    segmentIdx: number
    segment: Segment
}

type Props = {
    playbook: AnyPlaybookType
    playlist?: PlaylistType
    videoElement: HTMLVideoElement | null
}

const useVideoPlayAnalytics = ({ playbook, playlist, videoElement }: Props) => {
    const origin = useQueryParams('origin')

    const videoPlayData = useMemo(
        () => ({
            eventData: {
                url: window.location.href,
                origin,
                ...playbookToAnalyticsProps(playbook, playlist)
            },
            userProperties,
            globalProperties
        }),
        [origin, playbook, playlist]
    )

    const [sessionId, setSessionId] = useState<string | null>(null)

    const [isGetSessionLoading, setIsGetSessionLoading] = useState(false)
    // Get session token and pass params to Segment proxy(this request replaces logToAnalytics)
    const getSession = useCallback(
        async (body: any) => {
            setIsGetSessionLoading(true)
            const response = await request(
                `/tk/v1/get-session?pb-id=${playbook.id}`,
                'POST',
                body
            )

            setIsGetSessionLoading(false)
            setSessionId(response.sessionId)

            return response
        },
        [playbook.id]
    )

    const currentSegmentRef = useRef<CurrentSegment>({
        segment: { start: 0, end: 0 },
        segmentIdx: 0
    })

    const updateSession = useCallback(
        async (
            body: CurrentSegment & { sessionId: string; reason: string }
        ) => {
            await request('/tk/v1/update-session', 'POST', body).catch(
                async error => {
                    // Session expired, create new session and assign last unsaved segment to it
                    if (error.code === 404 && !isGetSessionLoading) {
                        currentSegmentRef.current = {
                            segment: currentSegmentRef.current.segment,
                            segmentIdx: 0
                        }

                        const response = await getSession({
                            ...videoPlayData,
                            reason: 'prevSessionExpired'
                        })
                        if (response) {
                            await updateSession({
                                reason: 'restartSession',
                                sessionId: response.sessionId,
                                ...currentSegmentRef.current
                            })
                        }
                    }
                }
            )
        },
        [getSession, isGetSessionLoading, videoPlayData]
    )

    const shouldUpdateSession = sessionId !== null

    const handleUpdateSession = useCallback(
        // reason - doesn't influence on calculation logic, used for logging and trouble-shooting in future
        async ({
            reason,
            onSuccess
        }: {
            onSuccess?: () => void
            reason: string
        }) => {
            if (shouldUpdateSession) {
                await updateSession({
                    sessionId,
                    reason,
                    ...currentSegmentRef.current
                })
                onSuccess?.()
            }
        },
        [shouldUpdateSession, updateSession, sessionId]
    )

    const [isPlaying, setIsPlaying] = useState(false)
    const intervalId = useRef(0)

    const stopKeepAlive = useCallback(() => {
        clearInterval(intervalId.current)
        intervalId.current = 0
    }, [])

    useEffect(() => {
        if (isPlaying) {
            // Send keep alive request each minute
            const id = setInterval(
                () => handleUpdateSession({ reason: 'timer' }),
                60 * 1000
            )
            intervalId.current = id as unknown as number

            return stopKeepAlive
        }

        stopKeepAlive()
    }, [handleUpdateSession, isPlaying, stopKeepAlive])

    useEffect(() => {
        if (!videoElement) return

        const handlePlay = () => {
            // Start new session or check is existing alive
            if (sessionId === null) {
                getSession({ ...videoPlayData, reason: 'init' })
            } else {
                handleUpdateSession({ reason: 'play' })
            }

            setIsPlaying(true)
        }

        videoElement.addEventListener('play', handlePlay)

        return () => {
            videoElement.removeEventListener('play', handlePlay)
        }
    }, [
        getSession,
        handleUpdateSession,
        sessionId,
        videoElement,
        videoPlayData
    ])

    const resetSegments = useCallback(() => {
        const currentSegment = currentSegmentRef.current

        currentSegmentRef.current = {
            segment: {
                start: currentSegment.segment.end,
                end: currentSegment.segment.end
            },
            segmentIdx: currentSegment.segmentIdx + 1
        }
    }, [])

    useEffect(() => {
        if (!videoElement) return

        const handlePause = () => {
            // Save changes, stop keep alive and start new segment
            setIsPlaying(false)

            handleUpdateSession({
                onSuccess: resetSegments,
                reason:
                    videoElement.duration ===
                    currentSegmentRef.current.segment.end
                        ? 'end'
                        : 'pause'
            })
        }

        videoElement.addEventListener('pause', handlePause)

        return () => {
            videoElement.removeEventListener('pause', handlePause)
        }
    }, [handleUpdateSession, resetSegments, videoElement])

    useEffect(() => {
        if (!videoElement) return

        const handleTimeUpdate = (event: Event) => {
            if (!event.target) return
            const time = (event.target as HTMLVideoElement).currentTime

            const currentSegment = currentSegmentRef.current
            const differenceInTime = time - currentSegment.segment.end

            // Skip forward or skip backwards - start of new segment
            // Skip forward starts from 2 seconds to distinguish this event between continuous video watch
            if (differenceInTime >= 2 || differenceInTime < 0) {
                handleUpdateSession({ reason: 'skip' })
                currentSegmentRef.current = {
                    segmentIdx:
                        sessionId !== null
                            ? currentSegment.segmentIdx + 1
                            : currentSegment.segmentIdx,
                    segment: { start: time, end: time }
                }

                return
            }

            currentSegmentRef.current = {
                segmentIdx: currentSegment.segmentIdx,
                segment: { start: currentSegment.segment.start, end: time }
            }
        }

        videoElement.addEventListener('timeupdate', handleTimeUpdate)

        return () => {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate)
        }
    }, [handleUpdateSession, sessionId, videoElement])

    useEffect(() => {
        if (!videoElement) return

        const eventHandler = () => {
            // Send current data each time user leaves page
            if (document.visibilityState === 'hidden' && shouldUpdateSession) {
                navigator.sendBeacon(
                    `${host}/tk/v1/update-session`,
                    JSON.stringify({
                        sessionId,
                        ...currentSegmentRef.current,
                        reason: 'visibilitychange'
                    })
                )
            }
        }

        document.addEventListener('visibilitychange', eventHandler, true)

        return () => {
            document.removeEventListener('visibilitychange', eventHandler, true)
        }
    }, [shouldUpdateSession, sessionId, videoElement])

    const willUnmount = useRef<boolean>(false)

    useEffect(() => {
        return () => {
            willUnmount.current = true
        }
    }, [])

    useEffect(() => {
        return () => {
            // Needed only in case if video still playing
            if (willUnmount.current && videoElement && !videoElement.paused) {
                handleUpdateSession({ reason: 'leavePage' })
                stopKeepAlive()
            }
        }
    }, [handleUpdateSession, stopKeepAlive, videoElement])
}

const CheckedCollectVideoPlayAnalytics = (props: Props) => {
    useVideoPlayAnalytics(props)

    return null
}

export const CollectVideoPlayAnalytics = ({ playbook, ...props }: Props) => {
    if (!isLocalhost) {
        // Key is very important here, re-starts everything on changing active playbook in playlist
        return (
            <CheckedCollectVideoPlayAnalytics
                playbook={playbook}
                {...props}
                key={playbook.id}
            />
        )
    }

    return null
}
