import { useEffect, useMemo, memo, useRef, useState, useCallback } from 'react'

import { Typography, LinearProgress } from '@material-ui/core'

import { HighlightedSegment } from '@guidde-co/shared.transcript.highlighted-segment'
import { SearchTranscript } from '@guidde-co/shared.transcript.search-transcript'
import { VirtualScroll } from '@guidde-co/shared.transcript.virtual-scroll'

import { uiId, logToAnalytics, playbookToAnalyticsProps } from 'modules'
import { PlaybookType, VideoRefType } from 'app/types'
import {
    SegmentType,
    Subtitle,
    TranscriptRange,
    TranscriptType
} from './TranscriptTypes'

const MIN_SEARCH_LENGTH = 2

type Props = {
    playbook: PlaybookType
    videoRef: VideoRefType
    transcript: TranscriptType
    transcriptIsLoading: boolean
    maxViewHeight?: number
}

/*
    Generate Transcript Ranges which is an array of objects, for example:
    [{
        start :0,
        finish: 4.8
    }]
    This is used to calculate the active subtitle and add to it a css red background
    in DictionaryWrapper transcriptText css class
*/

const generateTranscriptRanges = (transcript: Array<SegmentType>) => {
    if (!transcript) return []

    const res: Array<TranscriptRange> = []
    transcript.forEach((segment: SegmentType) => {
        segment.subtitles.forEach((subtitle: Subtitle) => {
            res.push({
                start: subtitle.start,
                finish: subtitle.start + subtitle.duration
            })
        })
    })
    return res
}

const checkActiveSegment = (
    realVideoTime: number,
    transcriptRanges: Array<TranscriptRange>,
    idx: number
) => {
    if (!transcriptRanges[idx]) return false

    return (
        transcriptRanges[idx].start < realVideoTime &&
        transcriptRanges[idx].finish > realVideoTime
    )
}

/*
    extract all the subtitles from all the segments into
    a single array
*/
const extractSubtitles = (transcript: Array<SegmentType>) => {
    if (!transcript) return []

    const res: Array<Subtitle> = []
    transcript.forEach((segment: SegmentType) => {
        segment.subtitles.forEach((subtitle: Subtitle) => {
            res.push({
                text: subtitle.text,
                start: subtitle.start,
                duration: subtitle.duration
            })
        })
    })
    return res
}

export const Transcript = memo(
    ({
        playbook,
        videoRef,
        transcript,
        transcriptIsLoading,
        maxViewHeight = 100
    }: Props) => {
        const [videoTime, setVideoTime] = useState(
            Number(videoRef.current?.currentTime)
        )

        useEffect(() => {
            const videoEL = videoRef?.current

            if (!videoEL) return

            const handleUpdate = (event: Event) => {
                if (!event.target) return
                const time = (event.target as HTMLVideoElement).currentTime

                setVideoTime(time)
            }

            videoEL.addEventListener('timeupdate', handleUpdate)

            return () => {
                videoEL.removeEventListener('timeupdate', handleUpdate)
            }
        }, [videoRef])

        const [searchString, setSearchString] = useState('')

        const activeSegmentRef = useRef(0)

        const virtuoso = useRef<any | null>(null)

        const subtitles = useMemo(() => {
            return extractSubtitles(transcript?.segments || [])
        }, [transcript?.segments])

        const transcriptRanges = generateTranscriptRanges(
            transcript?.segments || []
        )

        // Change video time according to clicked segment
        const handleSegmentClick = useCallback(
            (videoRef: VideoRefType) => (item: Subtitle) => {
                const seconds = item.start

                if (videoRef?.current) {
                    videoRef.current.currentTime = seconds
                    videoRef.current.play()
                    logToAnalytics('timestampClicked', {
                        ...playbookToAnalyticsProps(playbook),
                        timeOffset: item.start
                    })
                }
            },
            [playbook]
        )

        useEffect(() => {
            if (searchString?.length > 0) return

            const ind = subtitles?.findIndex((_: any, idx: number) =>
                checkActiveSegment(videoTime, transcriptRanges, idx)
            )

            if (ind === -1) return

            const activeIndex = ind === undefined || ind < 0 ? 0 : ind

            if (activeSegmentRef.current !== activeIndex) {
                activeSegmentRef.current = activeIndex
                virtuoso?.current?.scrollToIndex({
                    index: activeIndex,
                    align: 'start',
                    behavior: 'auto'
                })
            }
        }, [
            videoTime,
            transcriptRanges,
            transcript,
            subtitles,
            searchString?.length
        ])

        const filteredSubtitles = useMemo(() => {
            // For empty search string or search length under MIN_SEARCH_LENGTH return all the subtitles
            if (searchString === '' || searchString.length <= MIN_SEARCH_LENGTH)
                return subtitles

            // limit search results to 50 items
            const res = subtitles
                .filter(item =>
                    item.text.toLowerCase().includes(searchString.toLowerCase())
                )
                .slice(0, 50)

            logToAnalytics(res?.length ? 'search' : 'search-no-results', {
                text: searchString.toLowerCase(),
                hits: res?.length,
                inVideo: true,
                ...playbookToAnalyticsProps(playbook)
            })

            return res
        }, [searchString, subtitles, playbook])

        const onChange = (searchString: string) => {
            setSearchString(searchString)
        }

        const itemContent = useCallback(
            (idx: any, subtitle: Subtitle) => {
                return (
                    <>
                        <div key={idx} id={uiId(`subtitle-id-${idx}`)}>
                            <HighlightedSegment
                                searchWords={
                                    searchString.length <= MIN_SEARCH_LENGTH
                                        ? []
                                        : [searchString]
                                }
                                subtitle={subtitle}
                                onClick={() =>
                                    handleSegmentClick(videoRef)(subtitle)
                                }
                                isActive={checkActiveSegment(
                                    videoTime,
                                    transcriptRanges,
                                    idx
                                )}
                            />
                        </div>
                    </>
                )
            },
            [
                handleSegmentClick,
                transcriptRanges,
                videoTime,
                searchString,
                videoRef
            ]
        )

        if (transcriptIsLoading) return <LinearProgress />

        if (subtitles.length === 0) {
            return (
                <Typography>
                    There is no transcript for this video. Please check again
                    later.
                </Typography>
            )
        }

        return (
            <>
                <SearchTranscript onChange={onChange} />
                {filteredSubtitles?.length === 0 && (
                    <Typography>
                        We could not find a match for "{searchString}"
                    </Typography>
                )}
                <VirtualScroll
                    virtuoso={virtuoso}
                    maxViewHeight={maxViewHeight}
                    heightFromTop={168}
                    data={filteredSubtitles}
                    itemContent={itemContent}
                />
            </>
        )
    }
)
