import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    overflowEllipsis: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden'
    }
})

type Props = {
    tags: Array<string>
}

export const Tags = ({ tags }: Props) => {
    const classes = useStyles()

    if (!Array.isArray(tags) || !tags.length) return null

    const tagsAsText = tags
        .map(tag => `#${tag}`)
        ?.join(',')
        ?.replaceAll(',', ' ')

    return (
        <Box className={classes.overflowEllipsis} title={tagsAsText}>
            <Typography style={{ fontSize: '14px' }}>{tagsAsText}</Typography>
        </Box>
    )
}
