import { makeStyles, Tooltip, Typography, Box } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    description: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    multiLineDisplay: {
        whiteSpace: 'pre-line'
    }
}))

type Props = {
    description: string
}

export const Description = ({ description }: Props) => {
    const classes = useStyles()
    return (
        <Tooltip
            placement="top-start"
            title={
                <Box className={classes.multiLineDisplay}> {description}</Box>
            }
        >
            <Typography
                className={clsx(classes.description, classes.multiLineDisplay)}
            >
                {description}
            </Typography>
        </Tooltip>
    )
}
