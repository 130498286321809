import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    overrides: {
        MuiTypography: {
            body2: {
                fontSize: '0.75rem' // 12px
            },
            subtitle1: {
                fontSize: '1.2rem',
                fontWeight: 'bold'
            },
            h2: {
                fontSize: '1rem',
                fontWeight: 'bold',
                lineHeight: '1.5'
            }
        },
        MuiFormHelperText: {
            root: {
                color: 'red'
            }
        }
    },
    palette: {
        primary: {
            main: '#cd0000'
        },
        secondary: {
            main: '#1976d2'
        },
        text: {
            primary: '#212121'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        button: {
            textTransform: 'none'
        }
    },
    props: {
        MuiButton: {
            variant: 'contained',
            color: 'primary'
        }
    },
    zIndex: {
        tooltip: 1100
    }
})

export default theme
