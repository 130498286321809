import { useEffect, useState } from 'react'

import { Transcript, TranscriptType, TabsContainer } from 'components'
import { fetchTranscript } from 'modules'
import { useBoolean } from 'hooks'

import { Playlist } from './Playlist'

type Props = {
    playbook: any
    playlist: any
    videoRef: any
}

export const SidePanel = ({ playlist, playbook, videoRef }: Props) => {
    const [transcript, setTranscript] = useState<TranscriptType>({
        playbookId: '',
        segments: []
    })

    const transcriptLoading = useBoolean()

    const toggleLoading = transcriptLoading.toggle

    useEffect(() => {
        if (!playbook.transcriptUrl) return

        toggleLoading()

        fetchTranscript(playbook.transcriptUrl, setTranscript).then(
            toggleLoading
        )
    }, [playbook.transcriptUrl, toggleLoading])

    return (
        <TabsContainer
            tabs={[
                {
                    label: 'Playlist',
                    component: <Playlist playlist={playlist} />
                },
                {
                    label: 'Transcript',
                    component: (
                        <Transcript
                            playbook={playbook}
                            videoRef={videoRef}
                            transcript={transcript}
                            transcriptIsLoading={transcriptLoading.isTrue}
                        />
                    )
                }
            ]}
        />
    )
}
