import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import { ActionButton } from 'components/ActionButton'

import { generateUrlWithDomain } from 'modules'

const useStyles = makeStyles(theme => ({
    container: {
        color: '#212121',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 48px)'
    },
    content: {
        width: '400px',
        maxWidth: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        background: 'white',
        borderRadius: '4px',
        padding: theme.spacing(3, 2),
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
    },
    link: {
        border: 'none',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        }
    }
}))

type Props = {
    playbookId?: string
    utmContent?: string
    isPlaylist?: boolean
}

const PageNotFound = ({ playbookId, isPlaylist, utmContent }: Props) => {
    const classes = useStyles()

    // playlist or playbook path with playbookId
    const path = isPlaylist ? '/playlist' : '/playbooks'
    const finalPath = playbookId ? path + `/${playbookId}` : ''

    return (
        <Container className={classes.container}>
            <Box className={classes.content}>
                <Box mb={2}>
                    <Typography variant="h2">
                        This video's privacy settings require you to sign into
                        your Guidde account.
                    </Typography>
                </Box>

                <ActionButton
                    href={generateUrlWithDomain(finalPath)}
                    playbookId={playbookId}
                    uploadedByOrgId={utmContent}
                    isRestricted={true}
                >
                    View it on Guidde
                </ActionButton>
            </Box>
        </Container>
    )
}

export default PageNotFound
