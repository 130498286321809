import { useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { Typography, makeStyles } from '@material-ui/core'

import { getCtaLink, openLink } from 'modules'
import { publish } from 'modules/events'

import { useBoolean, useCtaReport } from 'hooks'
import { AnyPlaybookType, CallToActionType, SingleCtaType } from 'app/types'
import { paths } from 'app/paths'

import { SpacedGroup } from 'components'

const positionsSchema = {
    bottomLeft: {
        bottom: '5px',
        left: '5px'
    },
    bottomRight: {
        bottom: '5px',
        right: '5px'
    },
    topRight: {
        top: '5px',
        right: '5px'
    },
    topLeft: {
        top: '5px',
        left: '5px'
    }
} as const

const useStyles = makeStyles({
    cta: {
        cursor: 'pointer',
        position: 'absolute',
        height: '38px',
        maxWidth: 'max(300px, 40%)',
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid #E0E0E0',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
        transition: 'opacity 0.3s linear, bottom 0.3s linear',
        borderRadius: '4px',
        '&:hover': {
            boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.25)'
        }
    },
    image: {
        height: '30px'
    },
    text: {
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%'
    }
})

type Props = {
    videoElement: HTMLVideoElement | null
    cta: CallToActionType | SingleCtaType['action']
    playbook: AnyPlaybookType
    hoverControls?: boolean
}

export const CallToAction = ({
    cta,
    playbook,
    videoElement,
    hoverControls = false
}: Props) => {
    const classes = useStyles()

    const history = useHistory()

    const { reportEvent } = useCtaReport({ playbook, videoElement })

    const imageLoaded = useBoolean()

    const ctaTitle = cta && ('linkType' in cta ? cta.text : cta.text.label)

    const positionStyles = useMemo(() => {
        if (!cta.position) return {}
        if (
            hoverControls &&
            ['bottomLeft', 'bottomRight'].includes(cta?.position)
        ) {
            return {
                bottom: '50px',
                [cta.position === 'bottomLeft' ? 'left' : 'right']: '5px'
            }
        }

        return positionsSchema[cta.position]
    }, [hoverControls, cta.position])

    const ctaOpacity = !cta.image ? 1 : imageLoaded.isTrue ? 1 : 0

    return (
        <SpacedGroup
            className={classes.cta}
            m={1}
            px={0.5}
            onMouseEnter={() => {
                // publish a custom event to mark the video player that the mouse is over a CTA
                // to avoid flickering of the video controls and maintain the video controls hover state
                publish('ctaHovered', { state: true })
            }}
            onMouseLeave={() => {
                publish('ctaHovered', { state: false })
            }}
            onClick={() => {
                reportEvent(cta)

                if (!cta.link) return

                // CTA from quick guide
                if ('linkType' in cta) {
                    if (cta.linkType === 'guiddeLink') {
                        const playbookPath = generatePath(
                            paths.playbookDetails,
                            {
                                playbookId: cta.link
                            }
                        )

                        const playlistPath = generatePath(
                            paths.playlistDetails,
                            {
                                playlistId: cta.link
                            }
                        )

                        history.push(
                            `${
                                cta.mode === 'playlist'
                                    ? playlistPath
                                    : playbookPath
                            }?dark=true`
                        )

                        return
                    }

                    if (cta.linkType === 'goToStep' && videoElement) {
                        // For step link calculate the time by the step id and set the video current time
                        videoElement.currentTime = cta?.start || 0
                        videoElement.play()
                        return
                    }

                    openLink(
                        // temporary fix until will sort it out, the code stays as a placeholder
                        getCtaLink(cta),
                        '_blank'
                    ) // externalLink or internalLink
                    return
                }

                // CTA from regular video
                openLink(cta.link, '_blank')
            }}
            title={cta.link}
            style={{
                opacity: ctaOpacity,
                ...positionStyles
            }}
        >
            <SpacedGroup spacing={1} width="100%">
                {cta.image && (
                    <img
                        src={cta.image}
                        className={classes.image}
                        alt="cta"
                        onLoad={imageLoaded.setTrue}
                    />
                )}
                <Typography variant="body1" className={classes.text}>
                    <span>{ctaTitle}</span>
                </Typography>
            </SpacedGroup>
        </SpacedGroup>
    )
}
