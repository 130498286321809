import { ReactNode } from 'react'

import { Box } from '@material-ui/core'

type Props = {
    children: ReactNode
}

export const VideoOverlayContainer = ({ children }: Props) => {
    return (
        <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            borderRadius={8}
            zIndex={16}
            overflow="hidden"
        >
            {children}
        </Box>
    )
}
