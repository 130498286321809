import { useEffect } from 'react'

import { AppRouter } from 'components'

import './App.css'

import {
    detectBrowserWithVersion,
    envConfig,
    getOS,
    registerUserInAnalytics
} from 'modules'
import { useAuth } from 'hooks'

function App() {
    const { user } = useAuth()

    useEffect(() => {
        if (user) {
            // Identify user in Sentry + FullStory with the same props
            registerUserInAnalytics({
                uid: user.uid,
                env: envConfig.firebaseConfig.projectId,
                ...detectBrowserWithVersion(),
                ...getOS()
            })
        }
    }, [user])

    return <AppRouter />
}

export default App
