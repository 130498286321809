import { Box } from '@material-ui/core'

import { ReactComponent as GuiddeWhite } from 'assets/guidde_white.svg'
import { SpacedGroup } from './SpacedGroup'
import { openLink } from '../modules'

type Props = {
    status: boolean
    isDonePlaying: boolean
}

export const WaterMark = ({ status, isDonePlaying }: Props) => {
    if (!status) return null

    return (
        <SpacedGroup
            justifyContent="center"
            alignItems="center"
            fontSize="9px"
            position="absolute"
            zIndex={16}
            width="100%"
            bottom={isDonePlaying ? 10 : 22}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="11px"
                borderRadius={24}
                padding={1}
                color="white"
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    openLink(
                        'https://www.guidde.com?utm_source=embed&utm_medium=watermark',
                        '_blank'
                    )
                }}
            >
                <Box mr={0.5}>POWERED BY</Box> <GuiddeWhite width={45} />
            </Box>
        </SpacedGroup>
    )
}
