import { useMemo } from 'react'
import useSWR, { SWRConfiguration } from 'swr'

import { request } from 'modules'

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE'

type QueryPayloadType = { method: MethodType; body?: Record<string, any> }

export const useQuery = <T>(
    url: string,
    { method, body }: QueryPayloadType,
    params?: SWRConfiguration<T, Error>
) => {
    const { data, error, mutate, isValidating } = useSWR<T, any>(
        // use null for conditional fetching
        // see: https://swr.vercel.app/docs/conditional-fetching
        url ? [url, JSON.stringify(body)] : null,
        () => request(url, method, body),
        {
            ...params,
            shouldRetryOnError: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    )

    return useMemo(
        () => ({ data, error, mutate, isValidating }),
        [data, error, isValidating, mutate]
    )
}
