import { useEffect } from 'react'

import { AnyPlaybookType, PlaybookType } from 'app/types'
import { isPlaylist, logToAnalytics, playbookToAnalyticsProps } from 'modules'

type Props = {
    playbook: AnyPlaybookType
    playbookList?: Array<PlaybookType>
}

export const useVideoLoadAnalytics = ({ playbook, playbookList }: Props) => {
    useEffect(
        () => {
            const videoLoadPlaybooks = playbookList || [playbook]

            videoLoadPlaybooks.map(videoLoadPlaybook =>
                logToAnalytics('videoLoad', {
                    ...playbookToAnalyticsProps(
                        videoLoadPlaybook,
                        playbookList && isPlaylist(playbook)
                            ? playbook
                            : undefined
                    )
                })
            )
        },
        // Should be sent only once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
}
