import { CallToActionWrapperQG } from './CallToActionWrapperQG'
import { CallToActionWrapperVideo } from './CallToActionWrapperVideo'

import { isQG } from 'modules'

import { AnyPlaybookType } from 'app/types'

export type CallToActionWrapperProps = {
    videoElement: HTMLVideoElement | null
    playbook: AnyPlaybookType
    isFreePlan?: boolean
    onLastStepClickCta?: () => void
    onLastStepSkip?: () => void
}

export const CallToActionWrapper = ({
    playbook,
    videoElement,
    isFreePlan,
    onLastStepClickCta,
    onLastStepSkip
}: CallToActionWrapperProps) => {
    if (isQG(playbook)) {
        return (
            <CallToActionWrapperQG
                playbook={playbook}
                videoElement={videoElement}
                isFreePlan={isFreePlan}
                onLastStepClickCta={onLastStepClickCta}
                onLastStepSkip={onLastStepSkip}
            />
        )
    }
    return (
        <CallToActionWrapperVideo
            playbook={playbook}
            videoElement={videoElement}
        />
    )
}
