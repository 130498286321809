import { Typography, makeStyles, Box } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

const useStyles = makeStyles(theme => ({
    label: {
        color: '#7f8c9a',
        [theme.breakpoints.up('md')]: {
            fontSize: '10px'
        }
    }
}))

type Props = {
    label: string
    hidden?: boolean
    variant?: Variant
    mt?: number
    mb?: number
}

export const Label = ({ label, hidden, variant, ...rest }: Props) => {
    const classes = useStyles()

    if (!label || hidden) return null

    return (
        <Box {...rest}>
            <Typography variant={variant} className={classes.label}>
                {label}
            </Typography>
        </Box>
    )
}
