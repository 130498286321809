import { ReactNode, useMemo } from 'react'

import { Link } from '@material-ui/core'

type Props = {
    playbookId?: string
    uploadedByOrgId?: string
    children: ReactNode
    href: string
    isRestricted: boolean
}

type GenerateUtmParams = {
    playbookId: string
    uploadedByOrgId: string
    isRestricted: boolean
}

export const generateUtmParams = ({
    playbookId,
    uploadedByOrgId,
    isRestricted
}: GenerateUtmParams) => ({
    utm_source: 'guidde-app',
    utm_campaign: 'view-on-guidde',
    utm_term: playbookId,
    utm_content: uploadedByOrgId,
    ...(isRestricted ? { utm_medium: 'restricted-on-embed' } : {})
})

export const ActionButton = ({
    children,
    href,
    uploadedByOrgId = 'unknown',
    playbookId = 'unknown',
    isRestricted
}: Props) => {
    const utmParams = useMemo(() => {
        const query = new URLSearchParams()

        const params = generateUtmParams({
            playbookId,
            uploadedByOrgId,
            isRestricted
        })

        Object.entries(params).map(([key, value]) => query.append(key, value))

        return query.toString()
    }, [playbookId, uploadedByOrgId, isRestricted])

    return (
        <Link
            href={href + `?${utmParams}`}
            rel="noreferrer noopener"
            target="_blank"
            style={{ textDecoration: 'none' }}
        >
            {children}
        </Link>
    )
}
