import { forwardRef, useEffect } from 'react'
import { generatePath } from 'react-router-dom'

import clsx from 'clsx'

import { makeStyles, Box, Tooltip, Slide } from '@material-ui/core'

import { PlayControls } from '@guidde-co/shared.video-player.play-controls'
import { TimelineWithSections } from '@guidde-co/shared.video-player.timeline-with-sections'
import { CustomControls } from '@guidde-co/shared.video-player.controls.custom-controls'

import {
    ShareButton,
    onShareDialogOpenCallback,
    ShareDialog,
    PlaylistPrevNextButtons,
    WaterMark
} from 'components'
import { useBoolean, useClipboard, useNotification, useWindowView } from 'hooks'
import { generateUrlWithDomain } from 'modules'

import { VideoPlayerProps, getPlayerStatus } from './VideoPlayer'
import { Metadata } from './Metadata'

import { paths } from 'app/paths'
import { publish, subscribe, unsubscribe } from 'modules/events'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        background: 'black',
        borderRadius: 10
    },
    video: {
        height: '100vh',
        display: 'block'
    },
    shareButton: {
        position: 'absolute',
        height: 60,
        top: 0,
        right: 0
    },
    hoverControls: {
        opacity: 0,
        position: 'absolute',
        top: '70px',
        right: '23px',
        '& > *': {
            marginBottom: theme.spacing(1),
            '&:nth-child(2)': {
                transitionDelay: '0.1s'
            },
            '&:nth-child(3)': {
                transitionDelay: '0.2s'
            },
            '&:nth-child(4)': {
                transitionDelay: '0.3s'
            }
        }
    },
    animated: {
        '& > *': {
            opacity: 0,
            transform: 'translateX(30px)',
            transition: 'opacity, transform 0.1s linear'
        }
    },
    visible: {
        opacity: 1,
        zIndex: 1,
        '& > *': {
            opacity: 1,
            transform: 'translateX(0)'
        }
    },
    playControls: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 4
    },
    tooltip: {
        marginTop: theme.spacing(2.5)
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'black'
    },
    darkBgHover: {
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 1
    },
    prevNextButtons: {
        zIndex: 3,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    playerControls: {
        position: 'absolute',
        bottom: -10,
        left: 0,
        right: 0,
        zIndex: 2
    },
    gradient: {
        background:
            'linear-gradient(180deg, rgba(9, 12, 16, 0.00) 0%, rgba(9, 12, 16, 0.64) 100%)',
        height: 60,
        bottom: 10,
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: -1
    }
}))

type videoStatusByCtaType = {
    detail: { state: boolean }
}

export const DesktopVideoPlayer = forwardRef<
    HTMLVideoElement | null,
    VideoPlayerProps
>(
    (
        {
            hoverControls,
            hoverState,
            showGButtonAsIcon,
            showReactions,
            showFinishStep,
            playlist,
            hideWatermark,
            playbook,
            tooltip = '',
            onEnded,
            publicData,
            status,
            animationUrl,
            poster,
            showPoster,
            setStatus,
            playlistAutoPlay,
            ...videoProps
        }: any,
        ref: any
    ) => {
        const classes = useStyles()

        const {
            id: playbookId,
            url: playbookUrl,
            subtitlesUrl,
            isPlaylist
        } = playbook

        const { showSuccessNotification } = useNotification()

        const isClipboardSupported = useClipboard()

        const { mdDown } = useWindowView()

        const hidePlayControls = useBoolean()
        const shareDialog = useBoolean()
        const firstPlay = useBoolean(true)

        const isVideoPausedByCta = useBoolean()
        const isCtaHovered = useBoolean()
        const captions = useBoolean(Boolean(publicData?.showCaptions))

        useEffect(() => {
            const handleHover = (data: any) => {
                isCtaHovered.set(data.detail.state)
            }

            subscribe('ctaHovered', handleHover)

            return () => {
                unsubscribe('ctaHovered', handleHover)
            }
        }, [isCtaHovered])

        const setHideControls = hidePlayControls.set
        useEffect(() => {
            const showControls = status === 'loading' || status === 'playing'
            setHideControls(showControls)
        }, [status, setHideControls])

        const isVideoPausedByCtaSet = isVideoPausedByCta.set
        useEffect(() => {
            const handleVideoPaused = (data: videoStatusByCtaType) => {
                isVideoPausedByCtaSet(data.detail.state)
            }

            subscribe('videoPausedByCta', handleVideoPaused)

            return () => {
                unsubscribe('videoPausedByCta', handleVideoPaused)
            }
        }, [isVideoPausedByCtaSet])

        const playbookPath = generatePath(paths.sharePlaybook, { playbookId })
        const playlistPath = generatePath(paths.sharePlaylist, {
            playlistId: playbookId
        })

        const copyLinkPath = isPlaylist ? playlistPath : playbookPath

        const isNotPlaying = status !== 'playing'

        const showPlayButton = status !== 'loading' && hidePlayControls.isFalse
        const showMetadata = hoverState.isTrue && status == 'stopped'

        const showShareButton =
            hoverState.isTrue &&
            isNotPlaying &&
            !publicData?.hideButtonShareInEmbed

        const onShareIconClick = () => {
            shareDialog.setTrue()

            if (!isClipboardSupported) return

            onShareDialogOpenCallback(playlist || playbook)?.then(() => {
                showSuccessNotification('Link copied to the clipboard')
            })
        }

        const videoPoster = showPoster ? animationUrl || poster : undefined
        const isPlayControlsVisible =
            (showPlayButton || firstPlay.isTrue) && isVideoPausedByCta.isFalse

        return (
            <>
                <Box
                    className={classes.container}
                    onMouseEnter={hoverState.setTrue}
                    onMouseLeave={hoverState.setFalse}
                >
                    {showShareButton && (
                        <Box zIndex={3} className={classes.shareButton} p={3}>
                            <ShareButton
                                playbook={playlist || playbook}
                                onclick={shareDialog.setTrue}
                            />
                        </Box>
                    )}

                    <Box
                        position="relative"
                        style={{
                            cursor: 'pointer'
                        }}
                        // 100% - height of video controls
                        height="calc(100% - 55px)"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        zIndex={1}
                        onClick={() => {
                            if (
                                !ref?.current ||
                                status === 'loading' ||
                                videoProps.controls
                            )
                                return

                            if (status === 'playing') {
                                ref.current.pause()
                            } else {
                                ref.current.play()
                            }
                        }}
                    >
                        <video
                            crossOrigin="anonymous"
                            className={classes.video}
                            ref={ref}
                            poster={videoPoster}
                            width="100%"
                            height="auto"
                            controls={false}
                            onContextMenu={e => e.preventDefault()}
                            controlsList="nodownload"
                            {...videoProps}
                            src={playbookUrl}
                            muted={videoProps.autoPlay}
                            onPlay={() => {
                                firstPlay.setFalse()
                                videoProps?.onPlay()
                                setStatus('playing')
                            }}
                            onPause={() => {
                                videoProps?.onPause()
                                setStatus('stopped')
                            }}
                            onSeeking={() => setStatus('loading')}
                            onLoadedData={(e: any) => {
                                setStatus(getPlayerStatus(e))
                                if (
                                    videoProps.autoPlay ||
                                    playlistAutoPlay?.isTrue
                                ) {
                                    ref.current.play()
                                }
                            }}
                            onSeeked={(e: any) => setStatus(getPlayerStatus(e))}
                            onEnded={() => {
                                setStatus('stopped')
                                onEnded?.()
                            }}
                            onPlaying={hidePlayControls.setTrue}
                        >
                            {subtitlesUrl && captions.isTrue && (
                                <track
                                    label="English"
                                    kind="subtitles"
                                    srcLang="en"
                                    key={subtitlesUrl}
                                    src={subtitlesUrl}
                                    default
                                />
                            )}
                        </video>
                    </Box>
                    <Box
                        className={clsx(classes.bg, {
                            [classes.darkBgHover]: showMetadata
                        })}
                    />
                    <Metadata showMetadata={showMetadata} playbook={playbook} />
                    <Slide
                        timeout={700}
                        direction="up"
                        in={
                            (hoverState.isTrue && status === 'playing') ||
                            isCtaHovered.isTrue
                        }
                        mountOnEnter
                        unmountOnExit
                        onEnter={() => {
                            // publish a custom event to mark the cta that the video controls are visible
                            // so bottom cta location will be re-calculated
                            publish('hoverStateChanged', { state: true })
                        }}
                        onExit={() => {
                            publish('hoverStateChanged', { state: false })
                        }}
                    >
                        <Box className={classes.playerControls}>
                            <Box className={classes.gradient} />

                            <TimelineWithSections
                                sections={playbook.slicingSuggestion || []}
                                videoElement={ref.current as HTMLVideoElement}
                                updatedVersion={true}
                                key={playbookUrl}
                            />

                            <CustomControls
                                isFocused={hoverState.isTrue}
                                videoElement={ref.current}
                                onCaptionsClick={captions.toggle}
                                captionsInitialState={captions.isTrue}
                                chapters={playbook.slicingSuggestion}
                                transparent={true}
                                onShareIconClick={
                                    publicData?.hideButtonShareInEmbed
                                        ? undefined
                                        : onShareIconClick
                                }
                                autoPlay={playlistAutoPlay}
                                onOpenInGuiddeClick={
                                    showGButtonAsIcon
                                        ? undefined
                                        : () => {
                                              const openInGuiddeLink =
                                                  generateUrlWithDomain(
                                                      copyLinkPath
                                                  )

                                              window.open(
                                                  openInGuiddeLink,
                                                  '_blank'
                                              )
                                          }
                                }
                                showGuiddeIcon={showGButtonAsIcon}
                            />
                        </Box>
                    </Slide>

                    {!publicData.canHideWatermarkEmbed && !hideWatermark && (
                        <Box position="relative">
                            <WaterMark
                                status={status !== 'playing'}
                                isDonePlaying={
                                    ref?.current?.currentTime ===
                                    playbook.duration
                                }
                            />
                        </Box>
                    )}

                    <Tooltip
                        title={tooltip}
                        classes={{
                            popper: classes.tooltip
                        }}
                    >
                        <Box className={classes.playControls}>
                            <PlayControls
                                onClick={() => {
                                    if (status === 'playing') {
                                        ref.current.pause()
                                        setStatus('stopped')
                                    } else {
                                        ref.current.play()
                                        setStatus('playing')
                                    }
                                }}
                                color={playlist ? 'transparent' : 'white'}
                                visible={isPlayControlsVisible}
                                status={status}
                            />
                        </Box>
                    </Tooltip>
                    {!mdDown && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={clsx(
                                classes.hoverControls,
                                classes.animated,
                                hoverState.isTrue && classes.visible
                            )}
                        >
                            {hoverControls}
                        </Box>
                    )}
                    {playlist && hoverState.isTrue && status === 'playing' && (
                        <Box className={classes.prevNextButtons}>
                            <PlaylistPrevNextButtons
                                spacing={isNotPlaying ? 12 : 3}
                                videoRef={ref}
                            />
                        </Box>
                    )}
                </Box>
                <ShareDialog
                    isOpen={shareDialog.isTrue}
                    onClose={shareDialog.setFalse}
                    playbook={playbook}
                    height={Number(ref?.current?.clientHeight)}
                />
            </>
        )
    }
)
