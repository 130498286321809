import { generatePath } from 'react-router-dom'

import { SpacedGroup } from 'components'

import {
    IconButton,
    Dialog,
    Box,
    makeStyles,
    Typography,
    Tooltip
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { AnyPlaybookType } from 'app/types'
import { paths } from 'app/paths'

import { useClipboard, useWindowView } from 'hooks'

import {
    copyToClipboard,
    generateUrlWithDomain,
    logToAnalytics,
    playbookToAnalyticsProps
} from 'modules'

type Props = {
    isOpen: boolean
    height: number
    onClose: () => void
    playbook: AnyPlaybookType
}

const useStyles = makeStyles(theme => ({
    input: {
        width: '90vw !important',
        textAlign: 'center',
        background: 'transparent',
        border: 'none',
        borderColor: 'none',
        wordBreak: 'break-word',
        fontWeight: 600,
        fontSize: '16px',
        cursor: 'pointer',
        color: 'transparent',
        textShadow: '0 0 0 #FFF',
        '&:focus-visible': {
            outline: 'none'
        },
        '&:before': {
            border: 'none'
        },
        '&:after': {
            border: 'none'
        }
    },
    paper: {
        position: 'relative',
        background: 'transparent',
        boxShadow: 'none !important'
    },
    popper: {
        zIndex: theme.zIndex.modal + 1
    },
    container: {
        height: ({ height }: { height: number }) => height
    }
}))

export const generatePlaybookURL = ({
    isPlaylist,
    isPublic,
    id: playbookId
}: AnyPlaybookType) => {
    const playbookPath = generatePath(
        !isPublic ? paths.playbookDetails : paths.sharePlaybook,
        { playbookId }
    )

    const playlistPath = generatePath(
        !isPublic ? paths.playlistDetails : paths.sharePlaylist,
        { playlistId: playbookId }
    )

    const copyLinkPath = isPlaylist ? playlistPath : playbookPath

    return generateUrlWithDomain(copyLinkPath)
}

export const onShareDialogOpenCallback = (playbook: AnyPlaybookType) => {
    const target = playbook.isPlaylist ? 'playlist' : 'pb'

    logToAnalytics(
        `share_${target}_btn_clicked`,
        playbookToAnalyticsProps(playbook)
    )

    const playbookURL = generatePlaybookURL(playbook)
    return copyToClipboard(playbookURL, () => {
        logToAnalytics('copyLink', playbookToAnalyticsProps(playbook))
    })
}

export const ShareDialog = ({ playbook, onClose, isOpen, height }: Props) => {
    const classes = useStyles({ height })
    const { xsDown } = useWindowView()

    const isClipboardSupported = useClipboard()

    const playbookURL = generatePlaybookURL(playbook)

    return (
        <Dialog
            fullScreen
            fullWidth
            open={isOpen}
            onClose={onClose}
            classes={{
                paper: classes.paper,
                root: classes.container,
                container: classes.container
            }}
            BackdropProps={{
                style: {
                    borderRadius: xsDown ? 0 : 8,
                    overflow: 'hidden',
                    height
                }
            }}
        >
            <IconButton
                onClick={onClose}
                style={{ position: 'absolute', top: 20, right: 20 }}
            >
                <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
            <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <SpacedGroup flexDirection="column" alignItems="center">
                    <Typography style={{ color: '#FFF' }} align="center">
                        {isClipboardSupported ? 'Link Copied' : 'Share'}
                    </Typography>
                    <Tooltip
                        classes={{
                            popper: classes.popper
                        }}
                        title="Open link"
                    >
                        <input
                            ref={el => el?.select()}
                            onClick={() => window.open(playbookURL, '_blank')}
                            type="text"
                            className={classes.input}
                            defaultValue={playbookURL}
                        />
                    </Tooltip>
                </SpacedGroup>
            </Box>
        </Dialog>
    )
}
