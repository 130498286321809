import React, { useEffect } from 'react'
import { Box, LinearProgress } from '@material-ui/core'

import { useBoolean } from 'hooks'
import { delay } from 'modules'

type Props = {
    value: number
    index: number
    children: JSX.Element
}

export const TabPanel = ({ children, value, index }: Props) => {
    const load = useBoolean(false)

    const isLoaded = load.isTrue
    const setLoaded = load.setTrue

    useEffect(() => {
        if (isLoaded) return

        if (value === index) delay(500).then(setLoaded)
    }, [index, value, isLoaded, setLoaded])

    if (value === index && !isLoaded)
        return (
            <Box p={2}>
                <LinearProgress />
            </Box>
        )

    return (
        <Box hidden={value !== index}>
            {isLoaded && (
                <Box p={2} pb={0}>
                    {children}
                </Box>
            )}
        </Box>
    )
}
