import { Box, makeStyles, Slide, Tooltip, Typography } from '@material-ui/core'

import { PlaybookType } from 'app/types'

import { formatTime } from 'modules'

import clsx from 'clsx'

const useStyles = makeStyles({
    descriptionTooltip: {
        maxWidth: `${window.innerWidth - 30}px`
    },
    title: {
        '-webkit-line-clamp': 1
    },
    description: {
        fontSize: 14,
        '-webkit-line-clamp': 3
    },
    ellipsis: {
        position: 'relative',
        zIndex: 1,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
})

type Props = {
    showMetadata: boolean
    playbook: PlaybookType
    isMobile?: boolean
}
export const Metadata = ({ showMetadata, playbook, isMobile }: Props) => {
    const classes = useStyles()

    return (
        <Box position="absolute" bottom={0} left={0} right={0}>
            <Slide
                timeout={700}
                direction="up"
                in={showMetadata}
                mountOnEnter
                unmountOnExit
            >
                <Box zIndex={2} px={4} pb={2} position="relative" color="#fff">
                    <Box
                        position="absolute"
                        left={0}
                        right={0}
                        top={-20}
                        bottom={0}
                    >
                        <Box
                            style={{
                                background:
                                    'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%)',
                                height: '20%'
                            }}
                        />
                        <Box
                            bgcolor="rgba(0, 0, 0, 0.3)"
                            style={{
                                height: '80%'
                            }}
                        />
                    </Box>
                    <Typography
                        className={clsx(classes.title, classes.ellipsis)}
                        color="inherit"
                        style={{
                            fontSize: isMobile ? 16 : 24
                        }}
                    >
                        {playbook.title}
                    </Typography>
                    {!isMobile && (
                        <>
                            {playbook.description && (
                                <Tooltip
                                    title={playbook.description}
                                    classes={{
                                        tooltip: classes.descriptionTooltip
                                    }}
                                >
                                    <Box mt={1.2}>
                                        <Typography
                                            className={clsx(
                                                classes.description,
                                                classes.ellipsis
                                            )}
                                            color="inherit"
                                        >
                                            {playbook.description}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            )}
                            <Box mt={2} />
                            <Typography
                                style={{
                                    fontSize: 14,
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                color="inherit"
                            >
                                {formatTime(playbook.duration)}
                            </Typography>
                        </>
                    )}
                </Box>
            </Slide>
        </Box>
    )
}
