import { useContext } from 'react'

import { Box, BoxProps } from '@material-ui/core'

import { PlaylistContext } from 'pages/playlist/PagePlaylist'

import { ReactComponent as SkipPreviousIcon } from 'assets/arrow-left.svg'
import { ReactComponent as SkipNextIcon } from 'assets/arrow-right.svg'

import { SpacedGroup } from 'components'

import { VideoRefType } from 'app/types'

type Props = {
    onClick?: () => void
    spacing?: number
    videoRef: VideoRefType
}
export const PlaylistPrevNextButtons = ({
    onClick,
    videoRef,
    spacing
}: Props) => {
    const { activePlaybook, setActivePlaybook, playbookList } =
        useContext(PlaylistContext)

    const disablePrev = activePlaybook === 0
    const disableNext = activePlaybook === playbookList.length - 1

    const startPlaying = () => {
        if (!videoRef.current) return

        videoRef.current.currentTime = 0

        const play = () => {
            videoRef.current?.play()
            videoRef.current?.removeEventListener('canplaythrough', play)
        }

        videoRef.current.addEventListener('canplaythrough', play)
    }

    return (
        <SpacedGroup style={{ justifyContent: 'center' }} spacing={spacing}>
            <TransparentButton
                disabled={disablePrev}
                onClick={() => {
                    setActivePlaybook((prev: number) => prev - 1)
                    startPlaying()
                    onClick?.()
                }}
            >
                <SkipPreviousIcon />
                Prev video
            </TransparentButton>
            <TransparentButton
                disabled={disableNext}
                onClick={() => {
                    setActivePlaybook((prev: number) => prev + 1)
                    startPlaying()
                    onClick?.()
                }}
            >
                <SkipNextIcon />
                Next video
            </TransparentButton>
        </SpacedGroup>
    )
}

type TransparentButtonProps = {
    disabled: boolean
} & BoxProps
const TransparentButton = ({
    children,
    disabled,
    onClick,
    ...rest
}: TransparentButtonProps) => {
    if (disabled) {
        return <Box width={120} />
    }

    return (
        <SpacedGroup
            height={30}
            py={0.5}
            pl={1}
            spacing={2}
            fontSize={12}
            width={118}
            pr={2.5}
            bgcolor="rgba(0, 0, 0, 0.4)"
            color="#FFF"
            borderRadius={4}
            {...rest}
            style={{
                fontWeight: 500,
                cursor: disabled ? 'auto' : 'pointer'
            }}
            onClick={disabled ? undefined : onClick}
        >
            {children}
        </SpacedGroup>
    )
}
