import { ChangeEvent, useState } from 'react'

import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import { TabPanel } from './TabPanel'

const useStyles = makeStyles(theme => ({
    tabs: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: 'white',
        padding: theme.spacing(0.5, 0)
    },
    overflowScroll: {
        padding: theme.spacing(0, 1),
        overflow: 'overlay',
        height: 'calc(100vh - 56px - 16px)' // 56px - Tabs label, 16px - paddingTop
    }
}))

type Props = {
    tabs: Array<{
        label: string
        component: JSX.Element
    }>
    initialIndex?: number
}

export const TabsContainer = ({ tabs, initialIndex = 0 }: Props) => {
    const classes = useStyles()

    const [tabValue, setTabValue] = useState<number>(initialIndex)

    const labels = tabs.map(e => e.label)
    const components = tabs.map(e => e.component)

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={(_: ChangeEvent<any>, newValue: number) =>
                    setTabValue(newValue)
                }
                centered
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
            >
                {labels.map((label, idx) => (
                    <Tab label={label} key={idx} />
                ))}
            </Tabs>

            {components.map((component, idx) => (
                <TabPanel value={tabValue} index={idx} key={idx}>
                    <Box className={classes.overflowScroll}>{component}</Box>
                </TabPanel>
            ))}
        </>
    )
}
