import { useMemo } from 'react'

import { getAuth } from 'firebase/auth'

import { useAuth } from './use-auth'

export const usePlaybookAuth = () => {
    const { setLoginPage } = useAuth()

    return useMemo(
        () => ({
            onError: async (err: Error) => {
                const isAnonymous = await getAuth().currentUser?.isAnonymous

                // show login page ONLY if user is an anonymous.
                // if user IS NOT anonymous and received forbidden again
                // that means that video is restricted and we should show login.
                if (err.message === 'Forbidden') {
                    setLoginPage(Boolean(isAnonymous))
                }
            }
        }),
        [setLoginPage]
    )
}
