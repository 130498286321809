import { useCallback, useContext } from 'react'
import clsx from 'clsx'
import { generatePath, useHistory } from 'react-router-dom'

import { Box, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { ReactComponent as SKIP } from 'assets/skip.svg'
import { ReactComponent as ExternalLink } from 'assets/open_in_new.svg'

import { AnyPlaybookType, MultipleCtaType, QgCtaActionType } from 'app/types'
import { paths } from 'app/paths'
import { links } from 'app/links'

import { delay, getCtaLink, openLink, publish } from 'modules'
import { useCtaReport } from 'hooks'

import { SpacedGroup } from 'components'
import { PlaylistContext } from 'pages/playlist/PagePlaylist'

const useStyles = makeStyles(() => ({
    topContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
        zIndex: 15,
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px'
    },
    ctaVisible: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(3px)',
        width: '100%',
        height: '100%'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: 12
    },
    item: {
        borderRadius: '33px',
        width: '257px',
        maxWidth: '257px',
        height: '36px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
        padding: '0px 8px',
        cursor: 'pointer'
    },
    text: {
        fontWeight: 600,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        width: '100%',
        fontStyle: 'normal',
        overflow: 'hidden'
    },
    skip: {
        fontWeight: 500,
        fontSize: '14px',
        color: '#FFFFFF',
        cursor: 'pointer'
    }
}))

const STEP_TIME_OFFSET = 0.001

type Props = {
    qgCTA: MultipleCtaType
    videoElement: HTMLVideoElement | null
    playbook: AnyPlaybookType
    isFreePlan: boolean
    onSkip: () => void
    onLastStepClickCta?: () => void
}

export const CallToActionMultiple = ({
    onSkip,
    qgCTA,
    playbook,
    videoElement,
    isFreePlan,
    onLastStepClickCta
}: Props) => {
    const classes = useStyles()

    const history = useHistory()

    const { reportEvent } = useCtaReport({ playbook, videoElement })

    const { setActivePlaybook } = useContext(PlaylistContext)

    const handleCtaClick = useCallback(
        (item: QgCtaActionType) => {
            const { link, mode } = item

            const playbookPath = generatePath(paths.playbookDetails, {
                playbookId: link
            })

            const playlistPath = generatePath(paths.playlistDetails, {
                playlistId: link
            })

            if (mode === 'playlist') {
                // You are referring to the same playlist
                if (location.pathname === playlistPath) {
                    setActivePlaybook(0)
                } else {
                    history.push(playlistPath + '?dark=true')
                }
                return
            }

            // Playbook
            history.push(playbookPath + '?dark=true')
        },
        [history, setActivePlaybook]
    )

    return (
        <Box className={clsx(classes.topContainer, classes.ctaVisible)}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={2} color="white" fontSize="20px" fontWeight={600}>
                    {qgCTA.title}
                </Box>
                <SpacedGroup justifyContent="center">
                    <Box
                        className={classes.container}
                        style={{
                            maxWidth: `${
                                qgCTA?.actions.length > 3 ? 600 : 300
                            }px`
                        }}
                    >
                        {qgCTA?.actions.map((item, index: number) => (
                            <SpacedGroup
                                position="relative"
                                alignItems="center"
                                justifyContent="space-between"
                                fontSize="14px"
                                fontWeight="400"
                                className={classes.item}
                                id={`cta-${index}`}
                                key={index}
                                onClick={() => {
                                    reportEvent(item)
                                    // mark the round play button to go back to its original state and appear if the user stops the video
                                    publish('videoPausedByCta', {
                                        state: true
                                    })

                                    if (item.linkType === 'guiddeLink') {
                                        handleCtaClick(item)
                                        return
                                    }

                                    if (item.linkType === 'externalLink') {
                                        if (!item.link) return

                                        // temporary fix until will sort it out, the code stays as a placeholder
                                        openLink(getCtaLink(item), '_blank')
                                        return
                                    }

                                    if (!videoElement) return

                                    // For step link calculate the time by the step id and set the video current time
                                    videoElement.currentTime =
                                        item.start + STEP_TIME_OFFSET

                                    delay(100).then(() => videoElement.play())

                                    onLastStepClickCta?.()
                                }}
                            >
                                <SpacedGroup alignItems="center">
                                    <Box
                                        style={{
                                            marginLeft: '10px',
                                            marginRight: '4px',
                                            fontSize: '20px'
                                        }}
                                    >
                                        &#x2022;
                                    </Box>
                                    <Typography
                                        title={item.text}
                                        variant="body1"
                                        className={classes.text}
                                    >
                                        {item.text}
                                    </Typography>
                                </SpacedGroup>
                                {item.linkType === 'externalLink' && (
                                    <ExternalLink
                                        style={{
                                            width: '55px',
                                            marginRight: 0
                                        }}
                                    />
                                )}
                            </SpacedGroup>
                        ))}

                        <SpacedGroup
                            my={2}
                            className={classes.skip}
                            onClick={onSkip}
                        >
                            <SKIP />
                            <Box ml={1}>SKIP</Box>
                        </SpacedGroup>
                    </Box>
                </SpacedGroup>
            </Box>

            {isFreePlan && <PoweredByGuidde />}
        </Box>
    )
}

const PoweredByGuidde = () => (
    <Box
        fontSize="14px"
        position="absolute"
        color="#fff"
        left="18px"
        bottom="9px"
    >
        Powered by{' '}
        <a
            href={links.guiddeWebsite}
            rel="noreferrer"
            target="_blank"
            style={{ color: '#fff' }}
        >
            Guidde
        </a>
    </Box>
)
