import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useQueryParams = (param?: string) => {
    const search = useLocation()?.search

    const query = useMemo(() => new URLSearchParams(search), [search])

    return useMemo(() => {
        return param ? query.get(param) : Object.fromEntries(query.entries())
    }, [query, param])
}
