import { ReactElement } from 'react'
import {
    Box,
    Button,
    makeStyles,
    Tooltip,
    SvgIconProps
} from '@material-ui/core'

const useStyles = makeStyles({
    hoverButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(38 50 56 / 50%)',
        minWidth: '40px',
        minHeight: '40px',
        borderRadius: '50%',
        padding: 0,
        '&:hover': {
            backgroundColor: 'rgb(38 50 56 / 80%)'
        }
    }
})

type Props = {
    onClick: any
    title: string
    icon: ReactElement<SvgIconProps>
}

export const HoverButton = ({ onClick, title, icon }: Props) => {
    const classes = useStyles()

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
        >
            <Tooltip placement="left" title={title}>
                <Button
                    color="default"
                    onClick={onClick}
                    size="small"
                    className={classes.hoverButton}
                >
                    {icon}
                </Button>
            </Tooltip>
        </Box>
    )
}
