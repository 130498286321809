import { firebaseConfig } from '../env'

let extensionIds = [
    'chkfdipjdldekkcflcflmaopfioieiem',
    'jgnijfadmjdidipjnbbmnimpmbiippml',
    'hocgfmngoiokpfpanmilkknmgoehhien',
]

if (firebaseConfig.projectId === 'guidde-production') {
    extensionIds = [
        'oacmmmjedhheaijfjidilonpngccnhdl',
        'igndnknlpmojcdlckjjhgghnegpjfcpc',
        'pldeojbhedianlcaejpfbnckfhkacphe',
    ]
}

export const envConfig = {
    firebaseConfig,
    extensionIds,
}
