import { makeStyles, Box, BoxProps } from '@material-ui/core'

import clsx from 'clsx'

type StyleProps = {
    spacing: number
}

const useStyles = makeStyles(theme => ({
    row: {
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: ({ spacing }: StyleProps) => theme.spacing(spacing)
        }
    },
    column: {
        alignItems: 'flex-start',
        '& > *': {
            width: '100%'
        },
        '& > *:not(:first-child)': {
            marginTop: ({ spacing }: StyleProps) => theme.spacing(spacing)
        }
    }
}))

type Props = {
    spacing?: number
    flexDirection?: 'row' | 'column'
} & Omit<BoxProps, 'flexDirection'>

export const SpacedGroup = (props: Props) => {
    const {
        children,
        className,
        style,
        spacing = 2,
        flexDirection = 'row',
        display = true,
        ...rest
    } = props

    const classes = useStyles({ spacing })

    if (!display) return null

    return (
        <Box
            display="flex"
            flexDirection={flexDirection}
            className={clsx(className && className, classes[flexDirection])}
            style={style}
            {...rest}
        >
            {children}
        </Box>
    )
}
