import { paths } from './paths'
import { lazy } from 'react'

const PagePlaybook = lazy(() => import('pages/playbook/PagePlaybook'))
const PagePlaylist = lazy(() => import('pages/playlist/PagePlaylist'))
const PageGif = lazy(() => import('pages/gif/PageGif'))
const PageSsoLogin = lazy(() => import('pages/PageSsoLogin'))

export const routes = [
    { path: paths.playbookDetails, component: <PagePlaybook /> },
    { path: paths.playlistDetails, component: <PagePlaylist /> },
    { path: paths.gifDetails, component: <PageGif /> },
    { path: paths.ssoLogin, component: <PageSsoLogin /> }
]

export type RoutesType = typeof routes
