import { memo } from 'react'

import { ReactComponent as ShareIcon } from 'assets/share.svg'

import { PlaybookType } from 'app/types'

import { useClipboard, useNotification } from 'hooks'

import { onShareDialogOpenCallback } from './ShareDialog'
import { Typography } from '@material-ui/core'
import { SpacedGroup } from './SpacedGroup'

type Props = {
    playbook: PlaybookType
    onclick: () => void
}

export const ShareButton = memo(({ playbook, onclick }: Props) => {
    const isClipboardSupported = useClipboard()

    const { showSuccessNotification } = useNotification()

    return (
        <SpacedGroup
            spacing={1}
            color="#fff"
            title="Share"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                onclick()

                if (!isClipboardSupported) return

                onShareDialogOpenCallback(playbook)?.then(() => {
                    showSuccessNotification('Link copied to the clipboard')
                })
            }}
        >
            <ShareIcon color="inherit" />
            <Typography
                color="inherit"
                style={{
                    letterSpacing: 1.25,
                    fontWeight: 500,
                    fontSize: 14
                }}
            >
                SHARE
            </Typography>
        </SpacedGroup>
    )
})
